import logoLink from '../resources/notrufnoe.png';
import companyLogoLink from '../resources/notrufnoe.png';
import footerLogoLink from '../resources/notrufnoe_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#005A9A',
    panel_highlight: '#005A9A',
    chat_background_right: '#CCDEEB',
    chat_background_left: '#D8D8D8',

    active: '#005A9A',
    inactive: '#FFD900',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#CCDEEB',

    phonebook_dropdown: '#CCDEEB',
    phonebook_dropdown_svg: '#000',

    primary_light: '#CCDEEB',
    primary: '#005A9A',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#FFD900',
    text_on_secondary: 'white',
    focus_active: '#FFD900',

    red: '#AF2422',
    green: '#3AC13A',

    connecting: '#FFD900',
    connected: '#3AC13A',
    aborted: '#AF2422',

    font_color: 'black',
    link_color: '#005A9A',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#005A9A',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#FFD900',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Berliner Wasserbetriebe';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
